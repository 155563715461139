import React from "react";

export default function GasolinePrices() {
    return (
        <div className="w-full">
        <div className="p-4"> 
        <iframe title="Report Section" width="100%" height="1500" style={{borderRadius: '5px'}} src="https://app.powerbi.com/view?r=eyJrIjoiMzU4YTA1YjAtZjU1Yi00NzIyLWI2ZTktNzY4ZDIwODExZTg1IiwidCI6Ijg1M2ZkMjVjLTFiOGUtNDA0NC05M2QxLWM3Zjg1MmZlYTgyMiIsImMiOjEwfQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe>
        </div>
    </div>
    )
}