import React from 'react';


// in terms of CORS - may face issues with iOS devices (safari)

export default function CrudePrices() {
    
    return (
      <div className='w-full'>
        <div className="p-4"> 
        <iframe title="Report Section" width="100%" height="1200" style={{borderRadius: '5px'}} src="https://app.powerbi.com/view?r=eyJrIjoiNTE3YjNmYjMtNWI0My00MjhhLWFlMTEtMjYzYTYzMDVjZmI5IiwidCI6Ijg1M2ZkMjVjLTFiOGUtNDA0NC05M2QxLWM3Zjg1MmZlYTgyMiIsImMiOjEwfQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe>
        </div>
     
      </div>
    )
}