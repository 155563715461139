import React from "react";

export default function GasolineFinancials() {
    return (
        <div className="w-full">
        <div className="p-4">
        <iframe title="Report Section" width="100%" height="860" style={{borderRadius: '5px'}} src="https://app.powerbi.com/view?r=eyJrIjoiZGI5ODkwNGUtMzc3Mi00Y2M2LWIxMGUtYTM0OWMzMjllMDA0IiwidCI6Ijg1M2ZkMjVjLTFiOGUtNDA0NC05M2QxLWM3Zjg1MmZlYTgyMiIsImMiOjEwfQ%3D%3D&pageName=ReportSection452fb96905d7ce7758a7" frameborder="0" allowFullScreen="true"></iframe>
        </div>
    </div>
    )
}