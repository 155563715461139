import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
    title: {
        color: '#000',
        textAlign: 'center',
        marginTop: '2rem'
    },

      indicator: {
        backgroundColor: "black",
        height: "10px",
        top: "45px"
      },

      list: {
        minWidth: 120,
       
        '& .MuiInputLabel-root': {
            color: 'white',
            '& label.Mui-focused': {
                color: 'white'
            },
        },  
       
      
        '& .MuiSvgIcon-root': {
            color: 'white'
        },  
        '& .MuiOutlinedInput-root': {
            color: 'white',
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover fieldset': {
              borderColor: 'black',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white',
            },
          },
      }
   
  });

export const Onyx = () => {

    const classes = useStyles();


    return (
        <div className='crude-main'>

            <Typography className={classes.title} variant="h4"> ONYX </Typography>
            <div className="w-full">

              <div className="p-4"> 
              {<iframe title="COT Prod" width="100%" height="890" src="https://app.powerbi.com/reportEmbed?reportId=530ffc4d-566d-45ca-bf8d-0ac6c2278c1f&autoAuth=true&ctid=07723bcd-892c-43f9-80e9-a139c33096c1" frameborder="0" allowFullScreen="true"></iframe>}
              </div>
              </div>
          
        </div> 
       
    )
}