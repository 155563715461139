import Cookies from 'js-cookie'
import createAuth0Client from '@auth0/auth0-spa-js';
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN } from '../config' 

const selfUrl = window.location.origin
const changePasswordUrl = `https://${AUTH0_DOMAIN}/dbconnections/change_password`

let auth0
createAuth0Client({
    domain: AUTH0_DOMAIN,
    client_id: AUTH0_CLIENT_ID,
    redirect_uri: window.location.origin
}).then(res => auth0 = res)

const urlParam = param => param ? `/${param}` : ''

const urlQuery = query => {
    if (!query) {
        return ''
    } else {
        let url = ''
        Object.entries(query).forEach(([key, value], i) => {
            url = `${url}${i === 0 ? '?' : '&'}${key}=${value}`
        })
        return url
    }
}

export const forgetPassword = (email) => {
    return fetch(`${selfUrl}/api/public/forgetPassword`, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({ email }) }).then((response) => {
        if (response.ok) {
            return response.json().then(({ data }) => data);
        } else {
            throw new Error(response.statusText);
        }
    })
}

export const get = async (type, param, query, count = 0) => {
    const token = Cookies.get('token')
    const total = 3, interval = 5000
    return fetch(`${selfUrl}/api/${type}${urlParam(param)}${urlQuery(query)}`, { headers: { authorization: `Bearer ${token}` } })
    .then((response) => {
        if (response.ok) {
            return response.json();
        } else if (response.status === 401) {
            return logout()
        } else {
            throw new Error(response.statusText);
        }
    })
    .then((responseJson) => {
        const { meta, data, ...rest } = responseJson
        return data
    })
    .catch( async error => {
        if (count > total) {
            console.log('calling api fail', error, error.status)
        } else {
            console.log('calling api error', error, error.status)
            console.log(`wait 5s to retry ...`)
            // await sleep(interval)
            count += 1
            console.log(`retry (${count})`)
            return list2(type, param, query, count)
        }
        // return logout()
    });
}

export const post2 = async (path, body) => {
    const token = Cookies.get('token')
    return fetch(`${selfUrl}/api/${path}`, { method: 'POST', headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` }, body: JSON.stringify(body) }).then(res => res.json())
}


export const change_password = async () => {
    const email = Cookies.get('username')
    const client_id = AUTH0_CLIENT_ID
    const connection = 'Username-Password-Authentication'
    const body = { email, client_id, connection }
    return fetch(changePasswordUrl, { method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }).then(res => res.text())
}


export const login = async () => {
    try {
        await auth0.loginWithPopup({ audience: AUTH0_AUDIENCE })
    } catch (err) {
        return
    }
    const token = await auth0.getTokenSilently({ audience: AUTH0_AUDIENCE })
    Cookies.set('token', token, { expires: 14 })
    const { name, username, exp, rtrs, sao } = await list2('user')
    Cookies.set('name', name, { expires: 14 })
    Cookies.set('username', username, { expires: 14 })
    Cookies.set('exp', exp, { expires: 14 })
    Cookies.set('rtrs', rtrs, { expires: 14 })
    Cookies.set('sao', sao, { expires: 14 })
    window.location.reload();
}


export const logout = async () => {
    Cookies.remove('token')
    await auth0.logout()
    window.location.href = selfUrl
}


export const list2 = async (type, param, query, count = 0) => {
    const token = Cookies.get('token')
    const total = 3, interval = 5000
    return fetch(`${selfUrl}/api/${type}${urlParam(param)}${urlQuery(query)}`, { headers: { authorization: `Bearer ${token}` } })
    .then((response) => {
        if (response.ok) {
            return response.json();
        } else if (response.status === 401) {
            return logout()
        } else {
            throw new Error(response.statusText);
        }
    })
    .then((responseJson) => {
        const { meta, data, ...rest } = responseJson
        return data
    })
    .catch( async error => {
        if (count > total) {
            console.log('calling api fail', error, error.status)
        } else {
            console.log('calling api error', error, error.status)
            console.log(`wait 5s to retry ...`)
            // await sleep(interval)
            count += 1
            console.log(`retry (${count})`)
            return list2(type, param, query, count)
        }
        // return logout()
    });
}

