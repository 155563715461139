import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'

const useStyles = makeStyles({
    root: {
      margin: '0.5rem auto',
      width: '95%',
      backgroundColor: 'transparent',
      border: '3px solid #535C5D',
  
      "&::-webkit-scrollbar": {
        height: 2,
        },
      "&::-webkit-scrollbar-track": {
      backgroundColor: "white",
      
      },
      "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#535C5D", //#535c5D
      borderRadius: 2
      }
    },
    
    flexColumn: {
      width: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      justifyContent: 'center', 
      alignItems: 'center'
    },
    table: {
      minWidth: 650,
    },
    cell: {
      color: 'black',
      borderBottom: 'none',
      width: 75,
      '& .MuiFormLabel-root': {
        color: 'black'
      },
      '& .MuiOutlinedInput-root': {
        color: 'white',
        '& fieldset': {
          borderColor: 'gray',
        },
        '&:hover fieldset': {
          borderColor: 'black',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'black',
        },
      },
    },
  
    cellBorderedRight: {
      color: 'black',
      borderRight: '2px solid #747065',
      borderBottom: 'none',
      width: 100,
    },
    cellBorderedBottom: {
      color: 'black',
      borderBottom: '2px solid #747065',
      width: 75,
    },
    cellBorderedRightAndBottom: {
      color: 'black',
      borderRight: '2px solid #747065',
      borderBottom: '2px solid #747065'
    },
   
  });

export default function GasolinePriceDiffs() {
    const classes = useStyles();

    return (
       <div className={classes.flexColumn}>
               <TableContainer className={classes.root} component={Paper} elevation={5}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.cellBorderedRightAndBottom}>Gasoline / Front Month</TableCell>
                            <TableCell className={classes.cellBorderedBottom}>M1</TableCell>
                            <TableCell className={classes.cellBorderedBottom}>M2</TableCell>
                            <TableCell className={classes.cellBorderedBottom}>M3</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.cellBorderedRight}>92RON/EBOB</TableCell>
                            <TableCell className={classes.cell}>
                              <TextField label="Premium/Discount" variant="outlined" />
                            </TableCell>
                            <TableCell className={classes.cell}>
                              <TextField label="Premium/Discount" variant="outlined" />
                            </TableCell>
                            <TableCell className={classes.cell}>
                            <TextField label="Premium/Discount" variant="outlined" />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.cellBorderedRight}>RBOB/EBOB</TableCell>
                            <TableCell className={classes.cell}>
                              <TextField label="Premium/Discount" variant="outlined" />
                            </TableCell>
                            <TableCell className={classes.cell}>
                              <TextField label="Premium/Discount" variant="outlined" />
                            </TableCell>
                            <TableCell className={classes.cell}>
                            <TextField label="Premium/Discount" variant="outlined" />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.cellBorderedRight}>95RON/92RON</TableCell>
                            <TableCell className={classes.cell}>
                            <TextField label="Premium/Discount" variant="outlined" />
                            </TableCell>
                            <TableCell className={classes.cell}>
                            <TextField label="Premium/Discount" variant="outlined" />
                            </TableCell>
                            <TableCell className={classes.cell}>
                            <TextField label="Premium/Discount" variant="outlined" />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.cellBorderedRight}>Gas/Nap</TableCell>
                            <TableCell className={classes.cell}>
                            <TextField label="Premium/Discount" variant="outlined" />
                            </TableCell>
                            <TableCell className={classes.cell}>
                            <TextField label="Premium/Discount" variant="outlined" />
                            </TableCell>
                            <TableCell className={classes.cell}>
                            <TextField label="Premium/Discount" variant="outlined" />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                </TableContainer>

                <div className="row-right">
                  <Button variant="contained" color="success"> 
                      Submit
                  </Button>
                </div>
               
        </div>
    )
}