import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import React, { useState, useReducer } from "react";
import { Confirm } from './tabs';
import { Login, Loading } from './layout';
import './styles/index.scss';
import Cookies from 'js-cookie'
import { GlobalMessage } from './components'
import { StateContext, DispatchContext, initialState, reducer } from './reducers'
import HttpsRedirect from 'react-https-redirect';
import { Crude } from "./dashboards/Crude";
import { Gasoline, Onyx, Price, Refinery, Flows, China, Showcase, GasolineBeta } from "./dashboards";

const App = () => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const [authed] = useState(Cookies.get('token') ? true : false)
    
    if (!authed) return (
        <HttpsRedirect>
            <StateContext.Provider value={state} >
                <DispatchContext.Provider value={dispatch} >
                    
                    <Router>
                        <Loading spin={state.spin}/>
                        <Switch>
                            <Route exact path="/">
                                <Login />
                            </Route>
                            <Route exact path="/:path">
                                <Login />
                            </Route>
                            <Route path="/confirm/:code">
                                <Confirm />
                            </Route>
                            <Redirect to="/" />
                        </Switch>
                    </Router>
                </DispatchContext.Provider>
            </StateContext.Provider>
        </HttpsRedirect>
    )

    return (
        <HttpsRedirect>
            <StateContext.Provider value={state} >
                <DispatchContext.Provider value={dispatch} >
                    <Router>
                    <GlobalMessage newMessage={state.message} newSeverity={state.severity} newDuration={state.duration} />
                       <Loading spin={state.spin}/>
                      <Switch>
                          <Route exact path="/">
                              <Crude />
                          </Route>
                          <Route path="/crude">
                                <Crude />
                          </Route>
                          <Route path="/gasoline">
                                <Gasoline />
                          </Route>
                          <Route path="/onyx">
                                <Onyx />
                          </Route>
                          <Route path="/prices">
                                <Price />
                          </Route>
                          <Route path="/refinery">
                                <Refinery />
                          </Route>
                          <Route path="/flows">
                                <Flows />
                          </Route>
                          <Route path="/china">
                                <China />
                          </Route>
                          <Route path="/showcase">
                                <Showcase />
                          </Route>
                          <Route path="/gasoline-beta">
                                <GasolineBeta />
                          </Route>
                          <Redirect to="/" />
                      </Switch>  
                    </Router>
                </DispatchContext.Provider>
            </StateContext.Provider>
        </HttpsRedirect>
    )
};

export default App;
