import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect, useContext } from 'react';
import { DispatchContext } from "../reducers";


export default function CrudeFinancials() {
 


  return (
    <div className="w-full">
        <div className="p-4"> 
        <iframe title="Report Section" width="100%" height="860" style={{borderRadius: '5px'}} src="https://app.powerbi.com/view?r=eyJrIjoiOGNlMDgyYmYtZjMzYi00N2VlLWJkNTktNDYwMTU2MGJiYTYxIiwidCI6Ijg1M2ZkMjVjLTFiOGUtNDA0NC05M2QxLWM3Zjg1MmZlYTgyMiIsImMiOjEwfQ%3D%3D&pageName=ReportSection67628990d90cb3e43bdd" frameborder="0" allowFullScreen="true"></iframe>
        </div>  
    </div>
  ) 

}


