import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
    title: {
        color: '#000',
        textAlign: 'center',
        marginTop: '2rem'
    },

      indicator: {
        backgroundColor: "black",
        height: "10px",
        top: "45px"
      },

      list: {
        minWidth: 120,
       
        '& .MuiInputLabel-root': {
            color: 'white',
            '& label.Mui-focused': {
                color: 'white'
            },
        },  
       
      
        '& .MuiSvgIcon-root': {
            color: 'white'
        },  
        '& .MuiOutlinedInput-root': {
            color: 'white',
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover fieldset': {
              borderColor: 'black',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white',
            },
          },
      }
   
  });

export const Price = () => {

    const classes = useStyles();


    return (
        <div className='crude-main'>

            <Typography className={classes.title} variant="h4"> PRICES </Typography>
            <div className="w-full">

              <div className="p-4">  
              <iframe title="Report Section" width="100%" height="900" src="https://app.powerbi.com/view?r=eyJrIjoiMzA2YzU3YWEtNjQ1ZS00Yjk3LWIwYjktZDU1ZTVmYjc0MDBmIiwidCI6Ijg1M2ZkMjVjLTFiOGUtNDA0NC05M2QxLWM3Zjg1MmZlYTgyMiIsImMiOjEwfQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe>
               
              </div>
              </div>
          
        </div> 
       
    )
}