import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CrudeFundamentals from '../components/CrudeFundamentals';
import CrudePrices from '../components/CrudePrices';
import CrudePriceDiffs from '../components/CrudePriceDiffs'
import CrudeFinancials from '../components/CrudeFinancials';

const tabs = ['Fundamentals', 'Prices', 'Financials', 'Price Differentials']


const useStyles = makeStyles({
    title: {
        color: '#000',
        textAlign: 'center',
        marginTop: '2rem'
    },

      indicator: {
        backgroundColor: "black",
        height: "10px",
        top: "45px"
      },

      list: {
        minWidth: 120,
       
        '& .MuiInputLabel-root': {
            color: 'white',
            '& label.Mui-focused': {
                color: 'white'
            },
        },  
       
      
        '& .MuiSvgIcon-root': {
            color: 'white'
        },  
        '& .MuiOutlinedInput-root': {
            color: 'white',
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover fieldset': {
              borderColor: 'black',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white',
            },
          },
      }
   
  });

export const Crude = () => {
    const classes = useStyles();
    const [page, setPage] = useState('fundamentals') 
   

    const handlePageChange = (event, newValue) => {
           
        switch(newValue) {
          
            case 0: 
                setPage('fundamentals')
                break
            case 1: 
                setPage('prices')
                break
            case 2: 
                setPage('financials')
                break
            case 3: 
                setPage('price-diff')
                break
            default:
                setPage('fundamentals')
                break
        }
        
      };

      const getPageValue = () => {
            switch(page) {
              
                case 'fundamentals': return 0;
                case 'prices': return 1;
                case 'financials': return 2;
                case 'price-diff': return 3;
                default: return 0;
            }
      }

     
    return (
     
             
            <div className='crude-main'>
    
                <Typography className={classes.title} variant="h4"> CRUDE </Typography>
               
                <div className='crude-tabs-select'>
                    <Tabs
                    className="m-5"
                    value={getPageValue()}
                    onChange={handlePageChange}
                    textColor="inherit"
                    centered
                    TabIndicatorProps={{ className: classes.indicator }}
                    >
                      { tabs.map(tab => <Tab label={tab}/>) } 
                    
                    </Tabs>
                    
                </div>
                
            
                {
                    page === 'fundamentals' && <CrudeFundamentals />
                }
               
                {
                    page === 'prices' && <CrudePrices />
                }

                {
                    page === 'financials' && <CrudeFinancials />
                }

                {
                    page === 'price-diff' && <CrudePriceDiffs />
                }
            </div> 
           
    )
}