import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useState, useEffect, useContext } from 'react';
import { DispatchContext } from "../reducers";


export default function CrudeFundamentals() {
 
  //src="https://app.powerbi.com/view?r=eyJrIjoiMmM3ZTVkZTEtZjk0Ni00MGJlLWFmMTItZTljNzk1YTg2OTY5IiwidCI6Ijg1M2ZkMjVjLTFiOGUtNDA0NC05M2QxLWM3Zjg1MmZlYTgyMiIsImMiOjEwfQ%3D%3D" 
  return (
    <div className="w-full">
      <div className="p-4"> 
        <iframe title="Report Section" width="100%" height="2100" style={{borderRadius: '5px'}} src="https://app.powerbi.com/view?r=eyJrIjoiODhkMzVlMGMtYmJiNy00NjQyLTliMDAtODUxYjUzOWFjNDQwIiwidCI6Ijk5MDE0M2Q0LThmZTQtNDhjYy05NjZkLTU3YWM5NmE3YWYzMyIsImMiOjEwfQ%3D%3D" frameborder="0" allowFullScreen="true"></iframe>
      </div>    
    </div>
  ) 

}


