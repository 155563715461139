import { useState, useEffect } from 'react';
import { Snackbar, Slide } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const SlideTransition = props => <Slide {...props} direction="up" />

export const GlobalMessage = ({ newMessage='', newSeverity='success', newDuration=5000 }) => {
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState(newMessage)
    const [severity, setSeverity] = useState(newSeverity)
    const [duration, setDuration] = useState(newSeverity)

    useEffect(() => {
        if (newMessage) {
            setMessage(newMessage)
            setSeverity(newSeverity)
            setDuration(newDuration)
            setOpen(true)
        }
    }, [newMessage, newSeverity, newDuration])

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpen(false)
        setTimeout(() => {
            setMessage('')
            setSeverity('success')
            setDuration(5000)
        }, 500)
    }
    return message && 
    <Snackbar open={open} autoHideDuration={duration} onClose={handleClose} TransitionComponent={SlideTransition}>
        <MuiAlert className='w-full mt-2' elevation={10} variant="standard" onClose={handleClose} severity={severity}>{message}</MuiAlert>
    </Snackbar>
}
